import React, { ReactElement } from 'react'
import { Link } from 'gatsby'
import { rhythm } from '../utils/typography'

interface Props {
	projects: {
		node: {
			excerpt: string
			fields: {
				slug: string
			}
			frontmatter: {
				date: string
				title: string
				description: string
			}
		}
	}[]
}

const ProjectsList = ({ projects }: Props): ReactElement | null => {
	if (projects.length === 0) return null

	return (
		<>
			<h2>Projects</h2>
			{projects.map(({ node }) => {
				const title = node.frontmatter.title || node.fields.slug
				return (
					<article key={node.fields.slug}>
						<header>
							<h3
								style={{
									marginBottom: rhythm(1 / 4),
								}}
							>
								<Link style={{ boxShadow: `none` }} to={node.fields.slug}>
									{title}
								</Link>
							</h3>
						</header>
						<section>
							<p
								dangerouslySetInnerHTML={{
									__html: node.frontmatter.description || node.excerpt,
								}}
							/>
						</section>
					</article>
				)
			})}
		</>
	)
}

export default ProjectsList
