import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PostsList from '../components/postsList'
import ProjectsList from '../components/projectsList'
import { HeaderLarge } from '../components/header'

interface Props {
	data: {
		allMarkdownRemark: {
			edges: {
				node: {
					excerpt: string
					fields: {
						slug: string
					}
					frontmatter: {
						date: string
						title: string
						type: string
						description: string
					}
				}
			}[]
		}
		site: {
			siteMetadata: {
				postsEnabled: boolean
				projectsEnabled: boolean
			}
		}
	}
}

const IndexPage = ({ data }: Props): ReactElement => {
	const { postsEnabled, projectsEnabled } = data.site.siteMetadata
	const edges = data.allMarkdownRemark.edges
	const posts = edges.filter((edge) => edge.node.frontmatter.type !== 'Project')
	const projects = edges.filter(
		(edge) => edge.node.frontmatter.type === 'Project'
	)

	return (
		<Layout header={<HeaderLarge />}>
			<SEO />
			{/* <section id="mentoring">
				<h2>Mentoring</h2>
				<p>
					If you&apos;re interested in having me as your mentor, please{' '}
					<a href="/mentoring">apply here</a>
				</p>
			</section> */}
			{postsEnabled && <PostsList posts={posts} />}
			{projectsEnabled && <ProjectsList projects={projects} />}
		</Layout>
	)
}

export default IndexPage

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				postsEnabled
				projectsEnabled
			}
		}
		allMarkdownRemark(
			sort: { fields: [frontmatter___date], order: DESC }
			filter: { frontmatter: { published: { eq: true } } }
		) {
			edges {
				node {
					excerpt
					fields {
						slug
					}
					frontmatter {
						date(formatString: "YYYY-MM-DD")
						title
						type
						description
					}
				}
			}
		}
	}
`
