import React, { ReactElement } from 'react'
import { Link } from 'gatsby'
import { rhythm } from '../utils/typography'

interface Props {
	posts: {
		node: {
			excerpt: string
			fields: {
				slug: string
			}
			frontmatter: {
				date: string
				title: string
				description: string
			}
		}
	}[]
}

const PostsList = ({ posts }: Props): ReactElement | null => {
	if (posts.length === 0) return null

	return (
		<>
			<h2>Posts</h2>
			<ul className="article-list">
				{posts.map(({ node }) => {
					const title = node.frontmatter.title || node.fields.slug
					return (
						<li key={node.fields.slug}>
							<article>
								<header>
									<h3
										style={{
											marginBottom: rhythm(1 / 4),
										}}
									>
										<Link style={{ boxShadow: `none` }} to={node.fields.slug}>
											{title}
										</Link>
									</h3>
								</header>
								<section>
									<p
										dangerouslySetInnerHTML={{
											__html: node.frontmatter.description || node.excerpt,
										}}
									/>
								</section>
								<time dateTime={node.frontmatter.date}>
									<small>{node.frontmatter.date}</small>
								</time>
							</article>
						</li>
					)
				})}
			</ul>
		</>
	)
}

export default PostsList
